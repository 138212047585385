"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = formatDate;
exports.formatDateTime = formatDateTime;
exports.parseExifDateString = parseExifDateString;
function formatDateTime(date) {
  // returns format like: Sun, Jun 16, 2021, 08:42pm
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric"
  };
  if (!date) return "";
  const dateObj = new Date(date);
  if (!dateObj) return "";
  return dateObj.toLocaleDateString("en", dateOptions);
}
function formatDate(date) {
  // returns format like: Jun 16, 2021
  const dateOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };
  if (!date) return "";
  const dateObj = new Date(date);
  if (!dateObj) return "";
  return dateObj.toLocaleDateString('en-US', dateOptions);
}
function parseExifDateString(dateString) {
  var b = dateString.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]).toISOString();
}