import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

export const DropdownNavMenu = ({children, displayName="Menu"}) => (
  <Menu as="nav" className="relative z-30 inline-block text-left">
    <div>
      <Menu.Button className="inline-flex items-center justify-center w-full px-4 font-medium rounded hover:text-blue-700 focus-visible:ring-2 focus-visible:ring-blue-600 focus-visible:ring-opacity-75">
        {displayName}
        <ChevronDownIcon
          className="w-5 h-5 ml-2 -mr-1"
          aria-hidden="true"
        />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-75"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition-none"
    >
      <Menu.Items className="absolute right-0 w-64 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {children}
      </Menu.Items>
    </Transition>
  </Menu>
);

export const DropdownNavMenuItem = ({ item }) => (
  <Menu.Item>
    {({ active }) => (
      <span className={`${active ? 'bg-blue-50' : ''} flex items-center w-full p-2 pl-3 my-1 transition duration-150 ease-in-out rounded group hover:bg-blue-50 focus:outline-none focus-visible:ring focus-visible:ring-opacity-50`}>
        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 sm:h-12 sm:w-12">
          {item.icon}
        </div>
        <div className="ml-4">
          <p className="text-sm font-medium text-gray-900">
            {item.name}
          </p>
          <p className="text-sm text-gray-500">
            {item.description}
          </p>
        </div>
      </span>
    )}
  </Menu.Item>
);

export const SiteHeaderWrapper = ({ children, className="" }) => (
  <header className={`${className} w-full flex items-center justify-between bg-white h-10 px-4 py-0.5 border-b-2 border-gray-200`}>
    {children}
  </header>
);
