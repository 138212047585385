import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useClient from "../../util/useClient";
import { get, post } from "../../util/axios.js";
import { FeedbackContext } from "./FeedbackContext";
import { useLocation } from "react-router-dom";

export const AuthContext = React.createContext({
  user: null,
  isLoggedIn: false,
  authLoading: true,
  bouncerClient: null,
  loginUser: () => {},
  logoutUser: () => {},
  exportEvent: () => {},
  registerUser: () => {},
  resetPassword: () => {},
  requestResetPassword: () => {},
  updateAccountInformation: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { showErrorToast } = useContext(FeedbackContext);
  const { showSuccessToast } = useContext(FeedbackContext);

  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState(null);
  const location = useLocation();

  const bouncerClient = useClient(user);
  const getCurrentUser = bouncerClient.get("/api/auth/session");

  const isLoggedIn = !!user;

  useEffect(() => {
    if(location?.pathname.includes("work-complete") && !isLoggedIn) {
      const code = getQueryParam("code");
      const decodedUser = JSON.parse(atob(code))
      const email = decodedUser?.email
      const password = decodedUser?.password
      const tryLogin = bouncerClient.post("/api/login");
      if (!tryLogin.canAccess()) {
        return showErrorToast("User is already logged in");
      }
      const form = tryLogin.validateForm({ email, password});
      const onSuccess = (_user) => { setUser(_user);};
      if (!form.errors) { tryLogin.sendRequest(form, onSuccess);}
    }
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    setAuthLoading(true);
    const onSuccess = (_user) => {
      if (!!_user) setUser(_user);
      setAuthLoading(false);
    };
    const onError = (e) => {
      setAuthLoading(false);
      showErrorToast(e);
    };
    if (getCurrentUser.canAccess())
      await getCurrentUser.sendRequest({}, onSuccess, onError);
  };
  const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(name);
  };

  const loginUser = ({ email, password }, redirectTo) => {
    const tryLogin = bouncerClient.post("/api/login");
    if (!tryLogin.canAccess())
      return showErrorToast("user is already logged in");

    const onSuccess = (_user) => {
      setUser(_user);
      navigate(`${redirectTo}`);
    };
    const onError = (err) => showErrorToast(err);

    const form = tryLogin.validateForm({ email, password });
    if (!form.errors) tryLogin.sendRequest(form, onSuccess, onError);
  };

  const logoutUser = () => {
    const tryLogout = bouncerClient.get("/api/logout");
    if (!tryLogout.canAccess()) return showErrorToast("user is not logged in");

    const onSuccess = () => setUser(null);
    const onError = (err) => showErrorToast(err);

    tryLogout.sendRequest({}, onSuccess, onError);
  };
  const exportEvent = () => {
  
    const onSuccess = async (resData) => {
      try {
        const data =  resData?.events;
        const csvContent = convertToCSV(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `ProjectEvents_2024-06-01.csv`; 
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        showSuccessToast("File downloaded successfully.");
      } catch (err) {
        console.error(err);
        showErrorToast("Failed to download the file.");
      }
    };
  
    const onError = (err) => {
      showErrorToast(err.message);
    };
  
    // Assuming get is implemented as above or similar
    get("/api/exports/project_events/2024-06-01", onSuccess, onError);
  };
  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
  
    // Extract headers
    const headers = Object.keys(array[0]);
    str += headers.join(',') + '\r\n';
  
    // Extract data
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';
  
        line += array[i][index];
      }
      str += line + '\r\n';
    }
  
    return str;
  };

  const registerUser = (userData, successCallback, errorCallback) => {
    const onSuccess = (_user) => {
      successCallback();
      setUser(_user);
    };

    const onError = (err) => {
      showErrorToast(err);
      errorCallback();
    };

    post("/api/invite-user-link", { ...userData }, onSuccess, onError);
  };

  const requestResetPassword = ({ email }, successCallback) => {
    const sendResetPasswordRequest = bouncerClient.post(
      "/api/request-reset-password"
    );
    if (!sendResetPasswordRequest.canAccess())
      return showErrorToast("invalid request");

    const onSuccess = () => successCallback();
    const onError = (err) => showErrorToast(err);

    sendResetPasswordRequest.sendRequest({ email }, onSuccess, onError);
  };

  const resetPassword = (
    { resetPasswordToken, password },
    successCallback,
    errorCallback
  ) => {
    const tryResetPassword = bouncerClient.post("/api/reset-password-link");
    if (!tryResetPassword.canAccess()) return showErrorToast("invalid request");

    const onSuccess = () => {
      successCallback();
    };

    const onError = (err) => {
      showErrorToast(err);
      errorCallback();
    };
    const form = tryResetPassword.validateForm({
      resetPasswordToken,
      password,
    });
    if (!form.errors) tryResetPassword.sendRequest(form, onSuccess, onError);
  };

  const updateAccountInformation = (accountData) => {
    const onSuccess = (res) => {
      showSuccessToast(res);
    };
    const onError = (err) => {
      showErrorToast(err);
    };

    post("/api/account", { ...accountData }, onSuccess, onError);
  };

  const contextVal = {
    user,
    isLoggedIn,
    authLoading,
    bouncerClient,
    loginUser,
    logoutUser,
    exportEvent,
    registerUser,
    resetPassword,
    requestResetPassword,
    updateAccountInformation,
  };

  return (
    <AuthContext.Provider value={contextVal}>{children}</AuthContext.Provider>
  );
};
