"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHashIdFromStr = void 0;
// create hash from string to use as id
const getHashIdFromStr = str => {
  if (typeof str !== 'string') {
    console.warn('getHashIdFromStr: str must be a string');
    return;
  }
  ;
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i); // get char code
    hash = (hash << 5) - hash + char; // hash * 31 + char
    hash = hash & hash; // convert to 32bit integer
  }
  return hash;
};
exports.getHashIdFromStr = getHashIdFromStr;