import React, { useState, useEffect } from 'react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';

const ToastMessage = ({ toast = {}, onClose }) => {
  const { isSuccess, message } = toast || {};

  useEffect(() => {
    let timer;

    if (!toast) return;

    timer = setTimeout(() => {
      if (onClose) onClose();
    }, 4000);

    return () => clearTimeout(timer);
  }, [toast]);

  const Icon = isSuccess ? CheckCircleIcon : XCircleIcon;
  const textColors = isSuccess ? "bg-green-100 text-green-800 border-green-500" : "bg-red-200 text-red-800 border-red-500";
  const buttonColors = isSuccess ? "text-green-500 group-hover:text-green-600" : "text-red-600 group-hover:text-red-500";

  return (
    <div className={`${textColors} flex items-center justify-between fixed pl-4 py-2 pr-2 bg-white border rounded shadow-md z-500 min-w-72 max-w-120 bottom-6 right-5`}>
      <div className="flex flex-grow">{message}</div>
      <div className="flex items-center justify-end w-12 ml-4">
        <button title="close" className="group" onClick={onClose}>
          <Icon className={`${buttonColors} w-8 h-8 group-hover:drop-shadow-sm`} />
        </button>
      </div>
    </div>
  );
};

export const FeedbackContext = React.createContext({
  showErrorToast: () => { },
  showSuccessToast: () => { },
});


export const FeedbackContextProvider = ({ children }) => {
  const [toast, setToast] = useState(null);


  const showErrorToast = (errorMsg) => {
    setToast({
      message: errorMsg || "Error Occurred",
      key: Date.now(),
    });
  }

  const showSuccessToast = (successMsg) => {
    setToast({
      message: successMsg || "Success",
      isSuccess: true,
      key: Date.now(),
    });
  }

  const contextVal = {
    showErrorToast,
    showSuccessToast,
  };

  return (
    <FeedbackContext.Provider value={contextVal}>
      {children}
      {!!toast && (
        <ToastMessage
          key={toast.key}
          toast={toast}
          onClose={() => setToast(null)}
        />
      )}
    </FeedbackContext.Provider>
  );
}
