"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDevConsole = useDevConsole;
var _underscore = _interopRequireDefault(require("underscore"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// import React from 'react';

const STYLE_PROPS = {
  color: 'color',
  backgroundColor: 'background-color',
  border: 'border',
  font: 'font',
  padding: 'padding',
  fontFamily: 'font-family'
};
const getStyleStr = options => {
  let styleStr = '';
  if (!_underscore.default.isObject(options)) return styleStr;
  Object.keys(STYLE_PROPS).forEach(styleKey => {
    if (options[styleKey]) styleStr += `${[STYLE_PROPS[styleKey]]}: ${options[styleKey]}; `;
  });
  return styleStr;
};
const noOps = {
  dir: () => {},
  log: () => {},
  warn: () => {},
  clear: () => {},
  error: () => {},
  table: () => {},
  print: () => {},
  assert: () => {},
  logStep: () => {},
  logNote: () => {},
  logCounts: () => {},
  logError: () => {},
  logDanger: () => {},
  logWarning: () => {},
  logSuccess: () => {},
  logSecurity: () => {},
  logWithIcon: () => {},
  logHttpResponse: () => {},
  logSecurityWarning: () => {}
};
const dir = console.dir;
const log = console.log;
const warn = console.warn;
const clear = console.clear;
const table = console.table;
const error = console.error;
const assert = console.assert;
const print = function (msg) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const styleStr = getStyleStr(options);
  let args = [msg];
  if (!!styleStr) args = [`%c${msg}`, styleStr];
  for (var _len = arguments.length, otherArgs = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    otherArgs[_key - 2] = arguments[_key];
  }
  if (otherArgs.length) args.push(...otherArgs);
  return console.log(...args);
};
const logSuccess = function (msg) {
  for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }
  return print(`${EMOJIS.success} ${msg}`, {
    color: 'royalblue'
  }, ...args);
};
const logSecurity = function (msg) {
  for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
    args[_key3 - 1] = arguments[_key3];
  }
  return print(`${EMOJIS.shield} ${msg}`, {
    color: 'lightslategray'
  }, ...args);
};
const logError = function (msg) {
  for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
    args[_key4 - 1] = arguments[_key4];
  }
  return print(`${EMOJIS.nope} ${msg}`, {
    color: 'firebrick'
  }, ...args);
};
const logWarning = function (msg) {
  for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
    args[_key5 - 1] = arguments[_key5];
  }
  return print(`${EMOJIS.warning} ${msg}`, {
    color: 'orange'
  }, ...args);
};
const logStep = function (msg) {
  for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
    args[_key6 - 1] = arguments[_key6];
  }
  return print(`${EMOJIS.turtle} ~~~  ${msg} ~~~ ${EMOJIS.turtle}`, {
    fontFamily: 'monospace',
    color: '#808080'
  }, ...args);
};
const logWithIcon = function (iconName, msg) {
  const icon = EMOJIS[iconName] || '';
  for (var _len7 = arguments.length, args = new Array(_len7 > 2 ? _len7 - 2 : 0), _key7 = 2; _key7 < _len7; _key7++) {
    args[_key7 - 2] = arguments[_key7];
  }
  return print(`${icon} ${msg}`, {}, ...args);
};
const logHttpResponse = _ref => {
  let {
    msg = '',
    statusCode = 0
  } = _ref;
  let _msg = msg;
  if (!statusCode || isNaN(statusCode)) console.warn('logHttpResponse should receive both a message and status code parameter');
  if (typeof msg !== 'string') {
    console.warn('logHttpResponse should receive a string as a message parameter, received: ', msg);
    _msg = JSON.stringify(msg);
  }
  const msgAndStatusCode = `${_msg} (status ${statusCode})`;
  if (statusCode >= 400) {
    return logError(msgAndStatusCode);
  }
  if (statusCode >= 300) {
    return logWarning(msgAndStatusCode);
  }
  if (statusCode >= 200) {
    return logSuccess(msgAndStatusCode);
  }
  return log(msgAndStatusCode);
};
const logNote = function () {
  for (var _len8 = arguments.length, args = new Array(_len8), _key8 = 0; _key8 < _len8; _key8++) {
    args[_key8] = arguments[_key8];
  }
  return logWithIcon('note', ...args);
};
const logCounts = function () {
  for (var _len9 = arguments.length, args = new Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
    args[_key9] = arguments[_key9];
  }
  return logWithIcon('stats', ...args);
};
const logDanger = function () {
  for (var _len10 = arguments.length, args = new Array(_len10), _key10 = 0; _key10 < _len10; _key10++) {
    args[_key10] = arguments[_key10];
  }
  return warn(EMOJIS.exclamation, ...args);
};
const logSecurityWarning = function () {
  for (var _len11 = arguments.length, args = new Array(_len11), _key11 = 0; _key11 < _len11; _key11++) {
    args[_key11] = arguments[_key11];
  }
  return warn(EMOJIS.shield, ...args);
};
function useDevConsole() {
  if (process.env.NODE_ENV !== 'development') {
    return {
      ...noOps
    };
  }
  return {
    dir,
    log,
    warn,
    error,
    clear,
    print,
    table,
    assert,
    logStep,
    logNote,
    logCounts,
    logError,
    logDanger,
    logWarning,
    logSuccess,
    logSecurity,
    logWithIcon,
    logHttpResponse,
    logSecurityWarning
  };
}
;
const EMOJIS = {
  success: '✅',
  nope: '❌',
  warning: '🧐',
  turtle: '🐢',
  frog: '🐸',
  SOS: '🆘',
  siren: '🚨',
  airplane: '✈️',
  train: '🚂',
  ship: '🛳️',
  car: '🚗',
  bike: '🚲',
  bus: '🚌',
  truck: '🚚',
  connected: '🔌',
  info: 'ℹ️',
  question: '❓',
  exclamation: '❗️',
  exclaim: '❗️❗️',
  star: '⭐',
  rocket: '🚀',
  wifi: '📡',
  shield: '🛡',
  disk: '💾',
  folder: '📁',
  folderOpen: '📂',
  globe: '🌍',
  file: '📄',
  link: '🔗',
  thumbsUp: '👍',
  thumbsDown: '👎',
  surf: '🏄',
  ski: '🎿',
  snowboard: '🏂',
  snowman: '⛄',
  bomb: '💣',
  fire: '🔥',
  gift: '🎁',
  trophy: '🏆',
  medal: '🏅',
  heartBroken: '💔',
  cactus: '🌵',
  arrowUp: '⬆️',
  arrowDown: '⬇️',
  arrowLeft: '⬅️',
  arrowRight: '➡️',
  pencil: '✏️',
  pen: '🖊️',
  forward: '⏩',
  backward: '⏪',
  play: '▶️',
  pause: '⏸',
  stop: '⏹',
  key: '🔑',
  lock: '🔒',
  lockOpen: '🔓',
  turnKey: '🗝',
  canvas: '🖼️',
  clipboard: '📋',
  list: '📝',
  worker: '👷',
  paint: '🎨',
  geometry: '📐',
  tool: '🔧',
  hammer: '🔨',
  dot: '•',
  square: '□',
  triangle: '△',
  circle: '◯',
  duplicate: '📑',
  note: '📝',
  books: '📚',
  fountainPen: '🖋️',
  redCrayon: '🖍️',
  iceCube: '🧊',
  climber: '🧗',
  wizard: '🧙',
  abacus: '🧮',
  zombie: '🧟',
  timer: '🧭',
  puzzle: '🧩',
  genie: '🧞',
  palmTree: '🌴',
  tag: '🏷',
  date: '📅',
  number: '🔢',
  stats: '📊'
};
const phases = ['🌑', '🌒', '🌓', '🌔', '🌕', '🌖', '🌗', '🌘'];