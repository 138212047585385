"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortByKeyDesc = exports.sortByKey = exports.objSortDesc = exports.objSort = exports.dateObjSortDesc = exports.dateObjSort = void 0;
/**
 * 
 * pass as callback function to sort an array of objects by a given key
 * ie, arr.sort(objSort('name'))
 * takes a string as first parameter, which is the key to sort by, and a boolean as optional second parameter, which is the order to sort by (true = ascending (default), false = descending)
 * handles alphabetical sorting, numbers, and dateStrings
 * 
 */
const objSort = function (objKey) {
  let ascending = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return (a, b) => {
    // equal items sort equally
    if (a[objKey] === b[objKey]) {
      return 0;
    }
    // nulls sort after anything else
    else if (a[objKey] === null) {
      return 1;
    } else if (b[objKey] === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (ascending) {
      return a[objKey] < b[objKey] ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
      return a[objKey] < b[objKey] ? 1 : -1;
    }
  };
};
exports.objSort = objSort;
const objSortDesc = objKey => objSort(objKey, false);

/**
 * like objSort, specifically for dates
 */
exports.objSortDesc = objSortDesc;
const dateObjSort = function (objKey) {
  let ascending = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return (a, b) => {
    // equal items sort equally
    if (a[objKey] === b[objKey]) {
      return 0;
    }

    // nulls sort after anything else
    if (!a[objKey]) return 1;
    if (!b[objKey]) return -1;
    // otherwise, if we're ascending, earlier date sorts first
    else if (ascending) {
      return Date.parse(a[objKey]) < Date.parse(b[objKey]) ? -1 : 1;
    }
    // if descending, most recent date sorts first
    else {
      return Date.parse(a[objKey]) < Date.parse(b[objKey]) ? 1 : -1;
    }
  };
};
exports.dateObjSort = dateObjSort;
const dateObjSortDesc = key => dateObjSort(key, false);

/**
 * 
 * takes arr of objects, returns a sorted copy of the array
 * it calls objSort above, has more pre-checking and warnings and defaults for potential error
 * could be used where _arr or key variables change frequently or where logging is preferred
 * 
 */
exports.dateObjSortDesc = dateObjSortDesc;
const sortByKey = function (_arr, key) {
  let ascending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (!Array.isArray(_arr)) {
    console.warn(`sortByObjKey: first argument must be an array, received ${_arr}`);
    return _arr;
  }
  ;
  if (typeof key !== 'string') {
    console.warn('sortByObjKey: second argument must be a string');
    return _arr;
  }
  const arr = [..._arr];
  if (!arr.length) return arr;
  const isArrayofObjects = arr.every(item => typeof item === 'object' && !Array.isArray(item) && item !== null);
  if (!isArrayofObjects) {
    console.warn('Array passed to sortByObjKey is not an array of objects');
    return arr;
  }
  arr.forEach((item, i) => {
    if (!item.hasOwnProperty(key)) console.warn(`sortByObjKey: Object at index ${i} does not have the key ${key}`);
  });
  return arr.sort(objSort(key, ascending));
};
exports.sortByKey = sortByKey;
const sortByKeyDesc = (_arr, key) => sortByKey(_arr, key, false);
exports.sortByKeyDesc = sortByKeyDesc;