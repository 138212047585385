import React from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';

import { styles } from './Table.tailwind';

const CHECKBOX_COL_WIDTH = 50

export const Cell = ({ className = '', width = "100%", onClick = null, children }) => {
  return (
    <span className={`${styles.tableCell} ${className}`} style={{ width }} onClick={onClick}>
      {children}
    </span>
  )
};

export const Row = ({ className = '', children, onClick = null, hasCheckbox = false, isChecked = false, onCheck = null, overrideStyles = false }) => {

  const handleOnClickRow = () => {
    if (onClick) onClick();
  }
  const _styles = overrideStyles ? className : `${styles.tableRow} ${className}`
  return (
    <div className={_styles} onClick={handleOnClickRow}>
      {hasCheckbox && (
        <Cell width={CHECKBOX_COL_WIDTH} onClick={e => e.stopPropagation()}>
          <input
            className="cursor-pointer focus:ring-0"
            type="checkbox"
            title="Select row"
            checked={isChecked}
            onChange={onCheck}
          />
        </Cell>
      )}
      {children}
    </div>
  )
};

export const Table = ({
  children,
  columnHeaderDetails = [],
  onClickSort = null,
  ascending = false,
  hasHeaderCheckbox = false,
  headerCheckboxIsChecked = false,
  onCheckHeaderCheckbox = null,
  checkboxDisabled = false,
  photos = false
}) => {
  const sortedIcon = ascending ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />;
  return (
    <div className={`${photos ? styles.PhotoTableCont : styles.tableContainer}`}>
      <div className={!photos ? styles.tableHeader: undefined}>
        {hasHeaderCheckbox && !photos && (
          <Cell width={CHECKBOX_COL_WIDTH}>
            <input
              className="cursor-pointer disabled:cursor-not-allowed focus:ring-0"
              type="checkbox"
              disabled={checkboxDisabled}
              checked={headerCheckboxIsChecked}
              onChange={onCheckHeaderCheckbox}
            />
          </Cell>
        )}
        {columnHeaderDetails.map((col, i) => {
          const onClick = col.sortable ? () => onClickSort(col.key) : null;
          const fontStyle = col.sortBy ? 'text-gray-600 font-semibold' : 'text-gray-500';
          return (
            <Cell
              key={col.key}
              width={col.width}
              role="columnheader"
              aria-colindex={i}
              className={`${fontStyle} ${col.sortable ? 'cursor-pointer' : ''}`}
              onClick={onClick}
            >
              {col.displayName} {col.sortBy ? <span className="ml-1">{sortedIcon}</span> : ''}
            </Cell>
          );
        })}
      </div>
      {children}
    </div>
  )
}
