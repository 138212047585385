import React, { useState, useContext } from 'react';
import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';
import { get } from 'util/axios';
import { FeedbackContext } from 'shared/contexts/FeedbackContext';

const LogExportModal = ({ isOpen, onClose, apiEndpoint, title, convertToCSV, fileNamePrefix }) => {
  const { showErrorToast, showSuccessToast } = useContext(FeedbackContext);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [isDateValid, setIsDateValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = () => {
    if (!selectedStartDate) {
      setIsDateValid(false);
      return;
    }

    const onSuccess = async (resData) => {
      try {
        const data = resData?.events;
        if (data?.length === 0) {
          showErrorToast("No data found please select another date.");
          return;
        }
        const csvContent = convertToCSV(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fileNamePrefix}-${selectedStartDate}-${new Date().getTime()}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        onClose();
        showSuccessToast("File downloaded successfully.");
      } catch (err) {
        console.error(err);
        showErrorToast("Failed to download the file.");
      } finally {
        setIsLoading(false);
      }
    };

    const onError = (err) => {
      showErrorToast(err.message);
      setIsLoading(false);
    };

    setIsLoading(true);
    get(`${apiEndpoint}/${selectedStartDate}/${selectedEndDate}`, onSuccess, onError);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      titleCenter={true}
      size="large"
      title={title}
    >
      <div className="text-gray-700 grid grid-cols-2 px-4 text-lg gap-4">
        <div className="grid gap-1 justify-start">
          <span className='text-base'>Choose Start Date</span>
          <input
            type="date"
            className={`border rounded w-72 px-2 py-1 ${isDateValid ? 'border-gray-400' : 'border-red-500'}`}
            max={getCurrentDate()}
            onChange={(e) => {
              const newStartDate = e.target.value;
              setSelectedStartDate(newStartDate);
              setIsDateValid(true);
              if (selectedEndDate && new Date(newStartDate) > new Date(selectedEndDate)) {
                setSelectedEndDate('');
              }
            }}
          />
        </div>
        <div className="grid gap-1 justify-start">
          <span className='text-base'>Choose End Date</span>
          <input
            type="date"
            value={selectedEndDate}
            className={`border rounded w-72 px-2 py-1 ${isDateValid ? 'border-gray-400' : 'border-red-500'}`}
            min={selectedStartDate}
            max={getCurrentDate()}
            onChange={(e) => {
              setSelectedEndDate(e.target.value);
            }}
          />
        </div>
      </div>
      {!isDateValid && (
        <div className="text-red-500 text-sm text-center py-1 mb-2">Date is Required</div>
      )}
      <div className="flex justify-center gap-48 mt-4">
        <Button
          onClick={onClose}
          color="white"
          variant="modal"
          innerText="Cancel"
          disabled={isLoading}
        />
        <Button
          onClick={onConfirm}
          color="green"
          variant="modal"
          innerText={`Export`}
          disabled={!isDateValid || !selectedStartDate || isLoading}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default LogExportModal;
