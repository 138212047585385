import React, { useState } from 'react';
import { getPluralModifiers } from 'gmi-utils';

import Modal from '../../shared/components/Modal';
import Button from '../../shared/components/Button';

const DeleteRegionModal = ({ show,  onClose, onConfirm }) => {
  const [userConfirms, setUserConfirms] = useState(false);

  return (
    <Modal
      isOpen={show}
      handleClose={onClose}
      titleCenter={true}
      size="large"
      title={`Are you sure you want to delete ?`}
    >
     
      <div className="flex justify-center gap-48 mt-4">
        <Button
          onClick={onClose}
          color="white"
          variant="modal"
          innerText="Don't delete"
        />
        <Button
          onClick={onConfirm}
          color="red"
          variant="modal"
          innerText={`Delete Region`}
        />
      </div>
    </Modal>
  )
}

export default DeleteRegionModal;
