import { ViewListIcon } from "@heroicons/react/outline";
import axios from "axios";
import { formatDate } from "gmi-utils";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styles } from "shared/components/Modal.tailwind";
import { Cell, Row, Table } from "../../shared/components/Table/Table";
import Header from "./Header";
import Pagination from "./Pagination";

const columnFormatters = {
  updatedAt: (date) => <span>{formatDate(date)}</span>,
  default: (val) => (
    <span className="block truncate">{val === 0 || val ? val : "-"}</span>
  ),
};

const DuplicateProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [sortedBy, setSortedBy] = useState({ key: "name", asc: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [activeTab, setActiveTab] = useState("duplicateProjects");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("/api/duplicate-projects");
        const uniqueProjects = Array.from(
          new Set(response.data.map((project) => project.id))
        ).map((id) => response.data.find((project) => project.id === id));
        setProjects(uniqueProjects);
        setProjectCount(uniqueProjects.length);
        setCurrentPage(1);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(projects.length / projectsPerPage));
  }, [projects, projectsPerPage]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * projectsPerPage;
    const endIndex = startIndex + projectsPerPage;
    setSortedProjects(projects.slice(startIndex, endIndex));
  }, [projects, currentPage, projectsPerPage]);

  const onClickSort = (colKey) => {
    if (sortedBy.key === colKey)
      return setSortedBy((prev) => ({ ...prev, asc: !prev.asc }));
    return setSortedBy({ key: colKey, asc: true });
  };

  const customSort = (projects, key, asc) => {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });
    return projects.sort((a, b) => {
      const aValue = a[key] || "";
      const bValue = b[key] || "";
      const aIsNumber = !isNaN(aValue);
      const bIsNumber = !isNaN(bValue);

      if (aIsNumber && bIsNumber) {
        const aNumber = parseFloat(aValue);
        const bNumber = parseFloat(bValue);
        return asc ? aNumber - bNumber : bNumber - aNumber;
      } else {
        return asc
          ? collator.compare(aValue, bValue)
          : collator.compare(bValue, aValue);
      }
    });
  };

  useEffect(() => {
    setSortedProjects(customSort(sortedProjects, sortedBy.key, sortedBy.asc));
  }, [sortedProjects, sortedBy]);

  // if (loading) return <LoadingPhantomTable />;
  if (error)
    return (
      <div className="text-red-500 text-center text-lg">
        Error: {error.message}
      </div>
    );

  return (
    <>
      <Header />
      <div className={styles.subheaderTop}></div>
      <div className="py-0.5 mb-0.4 flex justify-between items-center h-12 text-sm bg-white text-gray-500 border-b-2">
        <div className="hidden m-5 sm:flex pt-2 pb-2 justify-between">
          <div className="min-w-min flex items-center text-gray-600 text-sm">
            <div className="hidden md:block md:mr-1">You have</div>
            <div className="md:block md:mr-1">{projectCount}</div>
            <div className="md:block md:mr-1">Duplicate projects</div>
          </div>
        </div>
        <div className="flex justify-end p-1 m-8">
          <span className="text-gray-500 text-sm pt-2">Projects per page</span>
          <select
            value={projectsPerPage}
            onChange={(e) => {
              setProjectsPerPage(parseInt(e.target.value, 20));
              setCurrentPage(1);
            }}
            className="h-9 w-15 ml-2 text-sm text-gray-500 border-gray-300 rounded-lg cursor-pointer"
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="All">All</option>
          </select>
        </div>
      </div>
      <div className="border-b  bg-white flex justify-between items-center">
        <ul className="flex">
          <li
            className={`cursor-pointer m-6 px-2 py-0.02 ${
              activeTab === "duplicateProjects"
                ? "border-b-2 border-blue-500 font-semibold"
                : ""
            }`}
            onClick={() => setActiveTab("duplicateProjects")}
          >
            Duplicate Projects
          </li>
        </ul>
        <Link to={"/dashboard"}>
          <button
            className="text-gray-800 font-normal hover:text-red-700 text-sm flex justify-end items-end w-auto h-auto border border-black hover:border-red-700 hover:bg-red-100 rounded-md py-1.5 px-2 mx-9"
            //   onClick={() => navigate("/dashboard")}
          >
            <ViewListIcon className="hover:text-red-700 w-5 h-5 mr-1.5" />
            All Projects
          </button>
        </Link>
      </div>

      <main className="p-1 md:p-4 lg:p-1">
        <section className="overflow-x-auto bg-blueGray-100 shadow-sm text-gray-500">
        {loading ? (
            <LoadingPhantomTable />
          ) : (
            activeTab === "duplicateProjects" && (
              <>
                <div className="p-4">
                  <Table
                    columnHeaderDetails={[
                      { key: "num", displayName: "Project Code", width: "30%" },
                      { key: "name", displayName: "Name", width: "30%" },
                      {
                        key: "updatedAt",
                        displayName: "Last Updated",
                        width: "30%",
                      },
                      { key: "status", displayName: "Status", width: "30%" },
                    ]}
                    onClickSort={onClickSort}
                    ascending={sortedBy.asc}
                  >
                    <div
                    className="overflow-y-auto hide-scrollbar "
                    style={{ maxHeight: "calc(100vh - 340px)" }}
                    >
                      {sortedProjects.map((project) => (
                        <Row
                          key={project.id}
                          className="bg-white cursor-pointer hover:bg-blueGray-50 "
                          >
                          {["num", "name", "updatedAt", "status"].map((key) => (
                            <Cell key={key} width="30%">
                              {columnFormatters[key]
                                ? columnFormatters[key](project[key])
                                : columnFormatters.default(project[key])}
                            </Cell>
                          ))}
                        </Row>
                      ))}
                      {!sortedProjects.length ? (
                      <Row className="bg-white flex justify-center ">
                      <div className="py-2 px-12 text-sm font-medium text-gray-500 tracking-wide">
                            No Matches
                          </div>
                        </Row>
                      ) : null}
                    </div>
                  </Table>
                  {projectsPerPage !== "All" &&
                    totalPages !== 0 &&
                    sortedProjects.length > 0 && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        goToPage={(page) => setCurrentPage(page)}
                        goToNextPage={() => setCurrentPage(currentPage + 1)}
                        goToPreviousPage={() => setCurrentPage(currentPage - 1)}
                      />
                    )}
                </div>
              </>
            )
          )}
        </section>
      </main>
    </>
  );
};

const LoadingPhantomTable = () => (
  <div
    className="w-full h-full flex items-center justify-center rounded border border-blueGray-100 bg-blueGray-200"
    style={{ minHeight: 800 }}
  >
    <div className="animate-pulse w-full h-full">
      <div className="w-full h-14 bg-blueGray-100 bg-opacity-50 px-2" />
      {[...Array(20)].map((_, i) => (
        <div
          key={i}
          className="w-full h-12 bg-blueGray-100 flex items-center space-x-0.5 py-1 px-6"
        >
          <div className="rounded w-5/12 h-full bg-blueGray-200 bg-opacity-40" />
          <div className="rounded w-3/12 h-full bg-blueGray-200 bg-opacity-40" />
          <div className="rounded w-4/12 h-full bg-blueGray-200 bg-opacity-40" />
        </div>
      ))}
    </div>
  </div>
);

export default DuplicateProjects;
