import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';

const SearchBar = React.forwardRef(({ onSearch, placeholder="", value='',width="" }, ref) => {
  return (
    <div className="relative flex text-gray-600 w-full h-full max-h-full">
      <input
        ref={ref}
        className="w-full py-1 px-5 pr-16 text-sm bg-white border-1 border-gray-300 rounded-md focus:outline-none placeholder-opacity-75 md:placeholder-opacity-50"
        type="search"
        name="search"
        placeholder={placeholder}
        onChange={onSearch}
        autoComplete="off"
        value={value}
      />
      <div className="absolute top-1/2 right-1.5 transform -translate-y-1/2">
        <SearchIcon className="w-4 h-4 text-gray-400" />
      </div>
    </div>
  );
});

export default SearchBar;
