// import React from 'react';

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError() {
//     return { hasError: true };
//   }

//   componentDidCatch() {
//     if (this.state.hasError) {
//       setTimeout(() => {
//         window.location.replace('/error');
//       }, 0);
//     }
//   }

//   render() {
//     if (this.state.hasError) {
//       return null;
//     }

//     return this.props.children; 
//   }
// }

// export default ErrorBoundary;


import React from 'react';
import { useNavigate } from 'react-router-dom';

class ErrorBoundary1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && !prevState.hasError) {
      this.props.navigate("/error"); 
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.navigate("/error", { replace: true });
    }

    return this.props.children;
  }
}
const ErrorBoundaryWrapper = (props) => {
  const navigate = useNavigate();
  return <ErrorBoundary1 {...props} navigate={navigate} />;
};

export default ErrorBoundaryWrapper;
