import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { put } from './util/axios';
import { FeedbackContext } from './shared/contexts/FeedbackContext';
import { FullPageLoadingSpinner, LoadingSpinner } from './shared/components/LoadingSpinner';

const InvitedUserRegisterForm = () => {
  const { showErrorToast, showSuccessToast } = useContext(FeedbackContext);
  const { email_signup_token } = useParams();

  const [loading, setLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(true);
  const [onSuccess, setOnSuccess] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const invalidPassword = !password || password.length < 8;

  const handleSetPassword = e => {
    const nextPassword = e.target.value;
    if (passwordErrorMessage && nextPassword.length >= 8) {
      setPasswordErrorMessage('');
    };
    setPassword(nextPassword);
  }

  useEffect(() => {
    axios.get(`/api/invite-user-link/${email_signup_token}`)
      .then(({ data }) => {
        setLoading(false);
        setIsExpired(false);
        setEmail(data.email);
        if (data.firstName) setFirstName(data.firstName);
        if (data.lastName) setLastName(data.lastName);
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response) {
          if (response.status === 401) return setIsExpired(true);
          if (response.status === 422) return showErrorToast(response.data);
          return showErrorToast(`error code ${response.status} received`);
        } else {
          showErrorToast('Could not successfully complete request');
        }
      });
  }, []);

  const invitedUserSetPassword = (data) => {
    const onSuccess = res => {
      if (res?.status === 200)
        showSuccessToast("User Added  Sucessfully")
      setLoading(false);
      setOnSuccess(true)
    };
    const onError = err => {
      setLoading(false)
      showErrorToast(err);
    }
    put(`/api/invite-user/update`, data, onSuccess, onError);
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
    const emailSignupToken = email_signup_token;
    invitedUserSetPassword({ emailSignupToken, password })
  }


  if (loading && isExpired) return <FullPageLoadingSpinner />;

  if (isExpired) return (
    <main className="p-10 text-lg bg-white">
      <p>This link has expired</p>
    </main>
  );


  if (onSuccess) return (
    <main className="p-10 text-lg bg-white">
      <p>Registration Successful</p>
    </main>
  );

  return (
    <main className="relative p-10 text-center bg-white h-full">
      <div className="w-full px-4 py-5 m-auto text-gray-500 border border-gray-300 rounded shadow-sm max-w-400px">
        <form className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-col w-full mt-4">
            <label htmlFor="email" className="mb-1 font-bold text-center text-black">Email</label>
            <input
              className="text-black disabled:cursor-not-allowed"
              id="email"
              type="text"
              placeholder="Email"
              defaultValue={email}
              disabled
            />
          </div>
          <div className="flex flex-col w-full mt-4">
            <label htmlFor="firstName" className="mb-1 font-bold text-center text-black">First Name</label>
            <input
              className="text-black"
              id="firstName"
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              disabled
            />
          </div>
          <div className="flex flex-col w-full mt-4">
            <label htmlFor="lastName" className="mb-1 font-bold text-center text-black">Last Name</label>
            <input
              className="text-black"
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              disabled
            />
          </div>
          <div className="flex flex-col w-full mt-4">
            <label htmlFor="password" className="mb-1 font-bold text-center text-black">Password</label>
            <input
              className="text-black"
              id="password"
              type="password"
              value={password}
              onChange={handleSetPassword}
              onBlur={() => {
                if (password.length < 8) setPasswordErrorMessage('Password must be at least 8 characters');
              }}
              required
            />
            <div className="h-6 text-right">
              {!!passwordErrorMessage && (
                <span className="text-sm text-blue-700">{passwordErrorMessage}</span>
              )}
            </div>
          </div>
          <input
            type="submit"
            className="w-full p-2 mt-4 text-lg font-bold text-white rounded cursor-pointer bg-teal hover:bg-tealDark disabled:hover:bg-teal disabled:cursor-not-allowed"
            onClick={(e) => handleSubmit(e)}
            disabled={!email || invalidPassword || loading}
            value="Sign Up"
          />
        </form>
      </div>
      {loading && <LoadingSpinner />}
    </main>
  );
}

export default InvitedUserRegisterForm;
