import React from "react";

import LoadingSpinner from "./LoadingSpinner";

const Button = ({
  onClick,
  innerText,
  color = "green",
  title = "",
  variant = "basic",
  disabled = false,
  type = "button",
  className = "",
  size = "md",
  loading = false,
  iconElement = null,
}) => {
  const variantEnums = Object.keys(styles.variants);

  let _variant = variant;
  if (!variantEnums.includes(_variant)) _variant = "basic";

  let _size = size;
  const sizeEnums = Object.keys(styles.sizes);
  if (!sizeEnums.includes(_size)) _size = "md";
  const sizeStyles = styles.sizes[_size];

  const cursor = disabled ? "cursor-default" : "";

  let buttonStyles = "";
  const { basic, modal } = styles.variants;
  if (_variant === "basic") {
    const colorStyles = basic[color] ? basic[color] : basic["green"];
    buttonStyles = `${colorStyles} ${sizeStyles} ${cursor} ${
      size === "xs" ? "px-[5px] py-1.5" : "px-[5px] py-1.5"
    } rounded inline-flex items-center`;
  }
  if (_variant === "modal") {
    const colorStyles = modal[color] ? modal[color] : modal["blue"];
    buttonStyles = `${colorStyles} ${sizeStyles} ${cursor} min-w-24 inline-flex justify-center px-4 py-2 font-medium border rounded disabled:opacity-50 `;
  }

  return (
    <button
      className={`${buttonStyles} ${className} ${loading ? "relative" : ""}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
      title={title}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      {loading ? (
        <LoadingSpinner small />
      ) : (
        <>
          {iconElement ? <span>{iconElement}</span> : null}
          <span>{innerText}</span>
        </>
      )}
    </button>
  );
};

const styles = {
  sizes: {
    xs: "text-xs 2xl:text-sm",
    sm: "text-xs",
    md: "text-xs md:text-sm",
    lg: "text-sm md:text-base",
    xl: "text-base md:text-lg",
  },
  variants: {
    basic: {
      red: "bg-red-500 disabled:bg-red-400 hover:bg-red-400 text-white",
      setScaleRed: " text-[#DA6B2C] border border-red-400",
      gray: "bg-gray-500 disabled:bg-gray-400 hover:bg-gray-400 text-white",
      blue: "bg-blue-500 disabled:bg-blue-400 hover:bg-blue-600 text-white",
      green: "bg-green-500 disabled:bg-green-400 hover:bg-green-400 text-white",
      copyGreen: "bg-[#12B981] w-20 flex justify-center items-center disabled:opacity-50 disabled:bg-green-400  hover:bg-green-400 text-white",
      copyWhite: "bg-white w-20 flex justify-center items-center disabled:opacity-50 disabled:bg-gray-50 disabled:text-gray-700 disabled:border-gray-400 hover:bg-blueGray-50 hover:text-blue-500 border border-gray-400 hover:border-blue-500 text-gray-700",
      white: "bg-white disabled:opacity-50 disabled:bg-gray-50 disabled:text-gray-700 p-1 disabled:border-gray-400 hover:bg-blueGray-50 hover:text-blue-500 border border-gray-400 hover:border-blue-500 text-gray-700",
      whiteDanger: "bg-white disabled:opacity-50 disabled:bg-gray-50 disabled:text-gray-700 disabled:border-gray-400 hover:bg-red-50 hover:text-red-700 border border-gray-400 hover:border-red-500 text-gray-700",
      whiteActive: "bg-blueGray-100 disabled:bg-gray-50 hover:bg-blueGray-50 hover:text-blue-500 border border-blue-400 hover:border-blue-500 text-blue-600",
    },
    modal: {
      white: "text-gray-700 border-gray-400 hover:border-blue-500 disabled:border-gray-400 hover:text-blue-500 disabled:text-gray-700 bg-white disabled:bg-gray-50 disabled:opacity-50 disabled:bg-white hover:bg-blueGray-50",
      lightGray: "text-white bg-gray-500 disabled:bg-gray-400 hover:bg-gray-600 focus:bg-gray-500",
      gray: "text-white bg-gray-600 disabled:bg-gray-600 hover:bg-gray-700 focus:bg-gray-500",
      blue: "text-blue-900 bg-blue-100 disabled:bg-blue-100 hover:bg-blue-200",
      indigo: "text-[#3C82F6] bg-[#F8FAFC] disabled:bg-white hover:bg-blue-50 border border-blue-500 hover:border-blue-700 hover:text-blue-700 disabled:border-blue-500",
      red: "text-red-700 bg-white disabled:bg-white hover:bg-red-50 border border-red-500 hover:border-red-700 disabled:border-red-500",
      green: "text-white bg-green-500 disabled:bg-green-100 hover:bg-green-200",
    },
  },
};

export default Button;
