"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderSheetNumsByDiscipline = orderSheetNumsByDiscipline;
exports.orderSheetsByDiscipline = orderSheetsByDiscipline;
var _objSort = require("../util/objSort");
function orderSheetNumsByDiscipline(sheetNums, sheetDisciplines) {
  if (!sheetNums) return console.warn('param not received');
  if (!(sheetDisciplines !== null && sheetDisciplines !== void 0 && sheetDisciplines.length)) return sheetNums.sort();
  let remainingNums = [...sheetNums];
  let orderedNums = [];
  let disciplineNums = {};

  // we want to loop thru the longest prefixes & match longer string patterns first
  const disciplinesOrderedByPrefix = [...sheetDisciplines].sort((0, _objSort.objSortDesc)('prefix'));
  disciplinesOrderedByPrefix.forEach(d => {
    const disciplineSheetNums = remainingNums.filter(num => (num || '').toUpperCase().startsWith(d.prefix));
    remainingNums = remainingNums.filter(num => !disciplineSheetNums.includes(num));
    disciplineNums[d.prefix] = disciplineSheetNums;
  });
  const disciplinesInOrder = [...sheetDisciplines].sort((0, _objSort.objSort)('order'));
  disciplinesInOrder.forEach(d => orderedNums = [...orderedNums, ...disciplineNums[d.prefix]]);
  return [...orderedNums, ...remainingNums].flat();
}

// order sheets according to SheetDiscipline.order if exists, 
function orderSheetsByDiscipline(sheets, sheetDisciplines) {
  if (!sheets) return console.warn('sheets param not received');
  if (!(sheetDisciplines !== null && sheetDisciplines !== void 0 && sheetDisciplines.length)) return sheets;
  let remainingSheets = [...sheets];
  let orderedSheets = [];
  let sheetDisciplinesMap = {};

  // we want to loop thru the longest prefixes & match longer string patterns first
  const sheetDisciplinesOrderedByPrefixDesc = [...sheetDisciplines].sort((0, _objSort.objSortDesc)('prefix'));
  sheetDisciplinesOrderedByPrefixDesc.forEach(d => {
    const disciplineSheets = remainingSheets.filter(sheet => (sheet.num || '').toUpperCase().startsWith(d.prefix));
    remainingSheets = remainingSheets.filter(sheet => !disciplineSheets.includes(sheet));
    sheetDisciplinesMap[d.prefix] = disciplineSheets;
  });
  const sheetDisciplinesInOrder = sheetDisciplines.sort((0, _objSort.objSort)('order'));
  sheetDisciplinesInOrder.forEach(d => orderedSheets = [...orderedSheets, ...sheetDisciplinesMap[d.prefix]]);
  return [...orderedSheets, ...remainingSheets].flat();
}