"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateDocumentFileSystem = void 0;
const generateDocumentFileSystem = documents => {
  const fs = {
    __documents: [] // documents in root
  };
  documents.forEach(document => {
    if (!document.dirPath || document.dirPath === '/') {
      if (document.title !== '.keep') fs.__documents.push(document);
      return;
    }
    const documentDirPath = document.dirPath.split('/').filter(Boolean);
    let currentFolder = fs;
    documentDirPath.forEach(dir => {
      if (!currentFolder[dir]) {
        currentFolder[dir] = {};
      }
      currentFolder = currentFolder[dir];
    });
    currentFolder.__documents = currentFolder.__documents || [];
    if (document.title !== '.keep') currentFolder.__documents.push(document);
  });
  return fs;
};
exports.generateDocumentFileSystem = generateDocumentFileSystem;