import { useNavigate } from 'react-router-dom';
import { DuplicateIcon, GlobeAltIcon } from '@heroicons/react/outline';
import { styles } from "shared/components/Modal.tailwind";
import Button from "../../shared/components/Button";
import Header from "./Header";
const Miscellaneouspage = () => {
    const navigate = useNavigate();
    const handleDuplicateProjects = () => {
        navigate("/duplicate-projects"); 
      };
    
      const handleManageRegions = () => {
         navigate("/manage-regions");
      
      };
    return (
        <>
        <Header />
        <div className={styles.subheaderTop}></div> 
        <div className="p-8 max-w-2xl mx-auto mt-8">
        <h1 className="text-4xl font-bold mb-6 text-center">Miscellaneous</h1>
        <p className="text-lg text-gray-700 mb-8 text-center">
          Welcome to the Miscellaneous section! Here, you'll find additional tools and features.
        </p>
  
     
        <div className="flex justify-center space-x-4">
        <Button
              className="flex items-center space-x-2  text-white text-3xl rounded-lg px-10 py-4 pr-4"
              iconElement={<DuplicateIcon className="w-6 h-6 ml-1" />}
              innerText="Duplicate Projects"
              color="green"
              onClick={handleDuplicateProjects}
            />
         <Button
              className="flex items-center space-x-2  text-white text-3xl transition duration-200 ease-in-out rounded-lg shadow-md px-10 py-4 pr-4"
              iconElement={<GlobeAltIcon className="w-6 h-6 ml-1" />}
              innerText="Manage Regions"
              color="green"
              onClick={handleManageRegions}
            />
      </div>
      </div>
      </>
    );
  };
  
  export default Miscellaneouspage;