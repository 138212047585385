import React, { Fragment, useRef } from "react";
import { Dialog, Transition, TransitionChild, DialogTitle } from "@headlessui/react";


const Modal = ({
  isOpen=true,
  handleClose,
  title="",
  titleNode=null,
  titleCenter=false,
  size="medium",
  children,
  initialFocusRef=null,
  closeOnClickaway=true,
  preventDefaultClose=false, // prevents close on click outside or 'esc'
  hasCustomTitleSection=false,
  overflowY="auto"
}) => {
  const cancelButtonRef = useRef();

  let _size = size;
  const sizeEnums = ["small", "medium", "large", "xl", "xxl"];
  if (!sizeEnums.includes(_size)) _size = "medium";
  
  let overflowYClassName = "overflow-y-auto";
  if (overflowY === "visible") overflowYClassName = "overflow-y-visible";

  const noOp = () => {};
  const shouldClose = preventDefaultClose ? noOp : handleClose;
  
  return (
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto bg-gray-200 bg-opacity-80"
          initialFocus={initialFocusRef || cancelButtonRef}
          open={isOpen}
          onClose={shouldClose}
        >
          <div className="px-4 text-center">
            <TransitionChild
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {closeOnClickaway && <Dialog className="fixed inset-0" onClose={noOp} /> }
            </TransitionChild>
            <TransitionChild
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              className="min-h-screen flex justify-center items-center"
            >
              <div className={`xl:max-w-2xl md:max-w-xl max-w-md inline-block w-full py-10 px-6 my-8 text-left  transition-all transform bg-white shadow-xl rounded-2xl`}>
                <DialogTitle
                  as={hasCustomTitleSection ? "div" : "h3"}
                  className={`text-lg py-4 font-medium leading-6 text-gray-900 ${titleCenter ? 'text-center' : ''}`}
                >
                  {hasCustomTitleSection ? titleNode : title}
                </DialogTitle>
                <div className={`mt-2 max-h-3/4-screen ${overflowYClassName}`}>
                  {children}
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
  );
}

export default Modal;
